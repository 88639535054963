exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-grants-and-iits-js": () => import("./../../../src/pages/grants-and-iits.js" /* webpackChunkName: "component---src-pages-grants-and-iits-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-medical-information-js": () => import("./../../../src/pages/medical-information.js" /* webpackChunkName: "component---src-pages-medical-information-js" */),
  "component---src-pages-pipeline-clinical-trials-essential-thrombocythemia-js": () => import("./../../../src/pages/pipeline/clinical-trials/essential-thrombocythemia.js" /* webpackChunkName: "component---src-pages-pipeline-clinical-trials-essential-thrombocythemia-js" */),
  "component---src-pages-pipeline-clinical-trials-polycythemia-vera-js": () => import("./../../../src/pages/pipeline/clinical-trials/polycythemia-vera.js" /* webpackChunkName: "component---src-pages-pipeline-clinical-trials-polycythemia-vera-js" */),
  "component---src-pages-pipeline-index-js": () => import("./../../../src/pages/pipeline/index.js" /* webpackChunkName: "component---src-pages-pipeline-index-js" */),
  "component---src-pages-research-essential-thrombocythemia-js": () => import("./../../../src/pages/research/essential-thrombocythemia.js" /* webpackChunkName: "component---src-pages-research-essential-thrombocythemia-js" */),
  "component---src-pages-research-ifn-use-in-mpns-js": () => import("./../../../src/pages/research/ifn-use-in-mpns.js" /* webpackChunkName: "component---src-pages-research-ifn-use-in-mpns-js" */),
  "component---src-pages-research-index-js": () => import("./../../../src/pages/research/index.js" /* webpackChunkName: "component---src-pages-research-index-js" */),
  "component---src-pages-research-mpn-guidelines-js": () => import("./../../../src/pages/research/mpn-guidelines.js" /* webpackChunkName: "component---src-pages-research-mpn-guidelines-js" */),
  "component---src-pages-research-polycythemia-vera-js": () => import("./../../../src/pages/research/polycythemia-vera.js" /* webpackChunkName: "component---src-pages-research-polycythemia-vera-js" */),
  "component---src-pages-resources-ae-reporting-js": () => import("./../../../src/pages/resources/ae-reporting.js" /* webpackChunkName: "component---src-pages-resources-ae-reporting-js" */),
  "component---src-pages-resources-congress-presentations-js": () => import("./../../../src/pages/resources/congress-presentations.js" /* webpackChunkName: "component---src-pages-resources-congress-presentations-js" */),
  "component---src-pages-resources-contact-medical-information-js": () => import("./../../../src/pages/resources/contact-medical-information.js" /* webpackChunkName: "component---src-pages-resources-contact-medical-information-js" */),
  "component---src-pages-resources-health-org-links-js": () => import("./../../../src/pages/resources/health-org-links.js" /* webpackChunkName: "component---src-pages-resources-health-org-links-js" */),
  "component---src-pages-resources-publications-js": () => import("./../../../src/pages/resources/publications.js" /* webpackChunkName: "component---src-pages-resources-publications-js" */),
  "component---src-pages-stability-assessment-calculator-js": () => import("./../../../src/pages/stability-assessment-calculator.js" /* webpackChunkName: "component---src-pages-stability-assessment-calculator-js" */)
}

